import '../scss/app.scss';

const templateCache = {};

const urlParams = new URLSearchParams(window.location.search);

const schoolId = parseInt(urlParams.get('id'), 10);
const limit = parseInt(urlParams.get('limit'), 10) || 2;

fetch(`https://${DOMAIN}/api/v1/schools/${schoolId}`)
    .then((response) => {
        if (response.ok) {
            response.json().then((school) => {
                const headerRenderer = tmpl(document.getElementById('header-template').innerHTML);
                const footerRenderer = tmpl(document.getElementById('footer-template').innerHTML);

                document.getElementById('header').innerHTML = headerRenderer(school);
                document.getElementById('footer').innerHTML = footerRenderer(school);

                fetch(`https://${DOMAIN}/api/v1/schools/${schoolId}/reviews?limit=${limit}`)
                    .then((response) => response.json())
                    .then((testimonials) => {

                        const testimonialRenderer = tmpl(document.getElementById('testimonial-template').innerHTML);

                        let html = '';
                        testimonials.forEach((item) => {
                            item.director = school.user;
                            html += testimonialRenderer(item);
                        });

                        document.getElementById('testimonials').innerHTML = html;
                    });
            });
        } else {
            const headerRenderer = tmpl(document.getElementById('header-template').innerHTML);
            const errorRenderer = tmpl(document.getElementById('error-template').innerHTML);

            document.getElementById('header').innerHTML = headerRenderer({
                url: `https://${DOMAIN}`,
                stars: false
            });

            document.getElementById('errors').innerHTML = errorRenderer({
                text: 'Автошкола не найдена',
            });
        }
    });

const tmpl = function (str, data) {
    // Figure out if we're getting a template, or if we need to
    // load the template - and be sure to cache the result.
    const fn = !/\W/.test(str) ?
        templateCache[str] = templateCache[str] ||
            tmpl(document.getElementById(str).innerHTML) :

        // Generate a reusable function that will serve as a template
        // generator (and which will be cached).
        new Function("obj",
            "var p=[],print=function(){p.push.apply(p,arguments);};" +

            // Introduce the data as local variables using with(){}
            "with(obj){p.push('" +

            // Convert the template into pure JavaScript
            str
                .replace(/[\r\t\n]/g, " ")
                .replace(/'(?=[^%]*#>)/g, "\t")
                .split("'").join("\\'")
                .split("\t").join("'")
                .replace(/<#=(.+?)#>/g, "',$1,'")
                .split("<#").join("');")
                .split("#>").join("p.push('")
            + "');}return p.join('');");

    // Provide some basic currying to the user
    return data ? fn(data) : fn;
}
